import React from 'react'
import Layout from '../components/layout/Layout'
import { graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import HomeSection from '../sections/createPages/HomeSection'
import TabSection from '../sections/createPages/TabSection'
import GallerySection from '../sections/createPages/GallerySection'

export const query = graphql`
  query ($slug: String!) {
    brandProjectsJson(slug: { eq: $slug }) {
      slug
      home {
        info
        title
        subTitle
        description
        text
        heroImage {
          childImageSharp {
            gatsbyImageData(
              width: 750
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              breakpoints: [768, 1380]
            )
          }
        }
      }
      tabs {
        slug
        tabTitle
        sliders {
          title
          subTitle
          description
          options {
            text
          }
          image {
            childImageSharp {
              gatsbyImageData(
                width: 750
                quality: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                breakpoints: [768, 1380]
              )
            }
          }
        }
      }
      gallery {
        original {
          publicURL
        }
        thumbnail {
          publicURL
        }
        vertical
      }
    }
  }
`

const BrandProject = ({ data, intl }) => {
  const brandProjectData = data.brandProjectsJson

  return (
    <Layout pageName={brandProjectData.slug}>
      <HomeSection
        info={intl.formatMessage({ id: brandProjectData.home.info })}
        title={intl.formatMessage({ id: brandProjectData.home.title })}
        subTitle={intl.formatMessage({ id: brandProjectData.home.subTitle })}
        description={intl.formatMessage({ id: brandProjectData.home.description })}
        text={intl.formatMessage({ id: brandProjectData.home.text })}
        image={brandProjectData.home.heroImage.childImageSharp.gatsbyImageData}
      />

      <TabSection
        intl={intl}
        sectionTitle={intl.formatMessage({ id: 'common.process' })}
        tabsData={brandProjectData.tabs}
        sliders={brandProjectData.tabs[0].sliders}
      />

      <GallerySection sectionTitle={intl.formatMessage({ id: 'common.lookbook' })} data={brandProjectData.gallery} />
    </Layout>
  )
}

export default injectIntl(BrandProject)
